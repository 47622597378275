import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1140,
  xl2: 1280,
  xxl: 1440,
  xxl2: 1499,
}

export const colors: Colors = {
  primary50: '#E7D000',
  gray80: '#414141',
  gray50: '#D0D0D0',
  gray5: '#F7F7F7',
  alert50: '#C8311D',
  white: '#FFFFFF',
  black: '#000000',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
}

export default theme
