exports.components = {
  "component---src-layouts-layout-blog-post-tsx": () => import("./../../../src/layouts/LayoutBlogPost.tsx" /* webpackChunkName: "component---src-layouts-layout-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dealerzy-tsx": () => import("./../../../src/pages/dealerzy.tsx" /* webpackChunkName: "component---src-pages-dealerzy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-strefa-wiedzy-tsx": () => import("./../../../src/pages/strefa-wiedzy.tsx" /* webpackChunkName: "component---src-pages-strefa-wiedzy-tsx" */)
}

